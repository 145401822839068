const production = {
  BASE_URL: 'https://api.sasdigital.com.br',
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  PORTAL_API: 'https://portal-api.sasdigital.com.br',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  CONTENT_PROGRAM_BASE_URL: 'https://sas-content-program.sasdigital.com.br',
  CONTRACT_EXCEPTION_URL: 'https://contracts-exceptions.sasdigital.com.br/api/v1',
  GA_DATA_PUBLISHER_API: 'https://ga-data-publisher.sasdigital.com.br/api/v1',
  UNLEASH_URL: 'https://unleash.sasdigital.com.br/proxy',
  SCHOOL_API: 'https://sas-schoolapi.sasdigital.com.br/api/v1/',
  SCHOOL_BFF: 'https://school-management-bff.sasdigital.com.br',
  NEW_HOME_URL: 'https://home.portalsaseducacao.com.br',
  REPORT_URL: 'https://report.portalsaseducacao.com.br/',
  TOGGLE_TEMP: 'https://api.sasdigital.com.br/eureka-frontend-bff',
}

const staging = {
  BASE_URL: 'https://api.staging.portalsaseducacao.com.br',
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  PORTAL_API: 'https://portal-api.staging.sasdigital.com.br',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  CONTENT_PROGRAM_BASE_URL: 'https://sas-content-program.staging.sasdigital.com.br',
  CONTRACT_EXCEPTION_URL: 'https://contracts-exceptions.staging.sasdigital.com.br/api/v1',
  GA_DATA_PUBLISHER_API: 'https://ga-data-publisher.staging.sasdigital.com.br/api/v1',
  UNLEASH_URL: 'https://unleash.sasdigital.com.br/proxy',
  SCHOOL_API: 'https://sas-schoolapi.staging.sasdigital.com.br/api/v1',
  SCHOOL_BFF: 'https://school-management-bff.staging.sasdigital.com.br',
  NEW_HOME_URL: 'https://home.staging.portalsaseducacao.com.br',
  REPORT_URL: 'http://report.staging.portalsaseducacao.com.br/',
  TOGGLE_TEMP: 'https://api.staging.sasdigital.com.br/eureka-frontend-bff',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]

export default urls
