import api from '@/api'
import urls from '@/utils/urls'

const config = { baseURL: urls.AUTH_API }

export default {
  getUser: () => api.get('v1/users/me', config),
  getServerDate: () => api.get('v1/users/me/currentDate', config),
  getReallocationLoginDateLimit: () => api.get(
    'v2/users/me/reallocation-login-date-limit',
    config,
  ),
  loginCTASASToCross: ({ login, token }) => api.post(`${urls.PORTAL_API}/sso-onboarding`, {
    login,
    token,
  }),
  getToggleRollout: (schoolId) => api.get(`${urls.TOGGLE_TEMP}/toggle/is_visible_school_management?userId=${schoolId}`),
  getPersonalData: () => api.get(`${urls.AUTH_API}/v2/personal-data`),
}
