import PageError from '@/views/PageError'
import lazyLoadView from './lazy-load-view'

const Home = () => lazyLoadView(import('@/views/Home'))
const StudentList = () => lazyLoadView(import('@/views/Students/List'))
const ClassroomList = () => lazyLoadView(import('@/views/Classroom/List'))
const TeacherList = () => lazyLoadView(import('@/views/Teacher/List'))
const ManagerList = () => lazyLoadView(import('@/views/Manager/List'))
const MigrationCodes = () => lazyLoadView(import('@/views/School/Migration'))
const ClassroomMigration = () => lazyLoadView(import('@/views/Classroom/Migration'))
const GroupsList = () => lazyLoadView(import('@/views/Groups/List'))
const Group = () => lazyLoadView(import('@/views/Groups/Group'))
const Classroom = () => lazyLoadView(import('@/views/Classroom/Classroom'))
const LandingPageToCross = () => lazyLoadView(import('@/views/LandingPageToCross'))

const routesWithPrefix = (prefix, routes) => routes.map((route) => ({
  ...route,
  path: `/${prefix}/${route.path}`,
}))

const adminRoutes = [
  {
    path: 'migracao',
    name: 'MigrationCodes',
    component: MigrationCodes,
    meta: {
      breadcrumb: {
        name: 'Migração de Códigos',
        to: '/admin/migracao',
      },
    },
  },
]

export const routesForPortalCross = [
  {
    path: '/',
    name: 'LandingPageToCross',
    component: LandingPageToCross,
  },
  {
    path: '/migrar-turmas',
    name: 'classroomMigration',
    component: LandingPageToCross,
  },
  {
    path: '/alunos',
    name: 'studentList',
    component: LandingPageToCross,
  },
  {
    path: '/turmas',
    name: 'classroomList',
    component: LandingPageToCross,
  },
  {
    path: '/turmas/editar/:id',
    name: 'classroomView',
    component: LandingPageToCross,
  },
  {
    path: '/professores',
    name: 'teacherList',
    component: LandingPageToCross,
  },
  {
    path: '/colaboradores',
    name: 'managerList',
    component: LandingPageToCross,
  },
  {
    path: '/grupos',
    name: 'groupsList',
    component: LandingPageToCross,
  },
  {
    path: '/grupos/editar/:id',
    name: 'groupView',
    component: LandingPageToCross,
  },
  {
    path: '/admin',
    redirect: '/',
  },
  {
    path: '*',
    name: 'NotFound',
    component: PageError,
  },
]

export const routesForPortalSAS = [
  {
    path: '',
    name: 'home',
    component: Home,
    meta: {
      breadcrumb: {
        name: 'Início PDC',
        to: '/',
      },
    },
  },
  {
    path: '/alunos',
    name: 'studentList',
    component: StudentList,
    meta: {
      breadcrumb: {
        name: 'Alunos',
        to: '/alunos',
      },
    },
  },
  {
    path: '/turmas',
    name: 'classroomList',
    component: ClassroomList,
    meta: {
      breadcrumb: {
        name: 'Turmas',
        to: '/turmas',
      },
    },
  },
  {
    path: '/turmas/editar/:id',
    name: 'classroomView',
    component: Classroom,
    meta: {
      breadcrumb: {
        name: 'Edição de turmas',
        to: '/turmas',
      },
    },
  },
  {
    path: '/professores',
    name: 'teacherList',
    component: TeacherList,
    meta: {
      breadcrumb: {
        name: 'Professores',
        to: '/professores',
      },
    },
  },
  {
    path: '/colaboradores',
    name: 'managerList',
    component: ManagerList,
    meta: {
      breadcrumb: {
        name: 'Colaboradores',
        to: '/colaboradores',
      },
    },
  },
  {
    path: '/migrar-turmas',
    name: 'classroomMigration',
    component: ClassroomMigration,
    meta: {
      breadcrumb: {
        name: 'Migrar turmas',
        to: '/migrar-turmas',
      },
    },
  },
  {
    path: '/grupos',
    name: 'groupsList',
    component: GroupsList,
    meta: {
      breadcrumb: {
        name: 'Grupos',
        to: '/grupos',
      },
    },
  },
  {
    path: '/grupos/editar/:id',
    name: 'groupView',
    component: Group,
    meta: {
      breadcrumb: {
        name: 'Edição de grupo',
        to: '/grupos',
      },
    },
  },
  {
    path: '/admin',
    redirect: '/',
  },
  ...routesWithPrefix('admin', adminRoutes),
  {
    path: '*',
    name: 'NotFound',
    component: PageError,
  },
]
