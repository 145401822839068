/* eslint-disable consistent-return */
import Router from 'vue-router'
import Vue from 'vue'
import { routesForPortalCross, routesForPortalSAS } from './routes'
import authService from '@/api/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const { data: personalData } = await authService.getPersonalData()

  if (!router.options.routes.length) {
    const { data: responseToggle } = await authService.getToggleRollout(personalData.schoolId)

    const selectedRoutes = responseToggle.isEnabled ? routesForPortalSAS : routesForPortalCross

    router.addRoutes(selectedRoutes)
    router.options.routes = selectedRoutes

    return next(to.fullPath)
  }
  next()
})

export default router
